import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, lang, meta, title }) {
  const { site, image, contentfulKeywords } = useStaticQuery(
    graphql`
      query siteOgImageAndSiteMetaData {
        image: file(relativePath: { eq: "og/logo-facebook-loony.png" }) {
          childImageSharp {
            fluid {
              aspectRatio
              base64
              originalName
              sizes
              src
            }
          }
        }
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        contentfulKeywords: contentfulConfig(
          configuration: { eq: "Site configuration" }
        ) {
          metadataKeywords
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const pageTitle = title || site.siteMetadata.title;
  const metadataKeywords = contentfulKeywords.metadataKeywords;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={pageTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: "Bienvenue chez Loony Communication",
        },
        {
          property: `og:image`,
          content: image.childImageSharp.fluid.src,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {metadataKeywords.map((el) => (
        <meta name="keywords" content={el} key={el} />
      ))}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
